import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import { Link } from 'gatsby'

const metaTags = {
  description: 'Browse the healthcare professionals sitemap to find a full list of pages on the SUPPRELIN® LA (histrelin acetate) site.',
  keywords: 'Homepage',
  title: 'HCP Sitemap | SUPPRELIN® LA (histrelin acetate)'
}


const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>Sitemap</h1>
      </Col>
    </Row>
    <Row>
        <Col xs={12}>
            <ul className='brand-list'>
                <li><Link to='/hcp'>HCP Home</Link></li>
            </ul>
            <h2>Clinical Data</h2>
            <ul className='brand-list'>
              <li><Link to='/hcp/lh-clinical-data'>LH – 1- &amp; 6-Year Data</Link></li>
              <li><Link to='/hcp/gonadal-hormone-clinical-data'>Gonadal – 1- &amp; 6-Year Data</Link></li>
              <li><Link to='/hcp/bone-age-to-chronological-age'>BA:CA Ratio</Link></li>
              <li><Link to='/hcp/safety-tolerability-profile'>Safety Profile</Link></li>
            </ul>
            <h2>Dosing</h2>
            <ul className='brand-list'>
              <li><Link to='/hcp/12-month-dosing'>12-Month Dosing</Link></li>
            </ul>
            <h2>Procedure</h2>
            <ul className='brand-list'>
              <li><Link to='/hcp/procedure/'>Implant &amp; Kit</Link></li>
              <li><Link to='/hcp/insertion-removal'>Insertion &amp; Removal</Link></li>
              <li><Link to='/hcp/post-procedure-care'>Post-Procedure Care</Link></li>
            </ul>
            <h2>Access &amp; Copay</h2>
           <ul className='brand-list'>
              <li><Link to='/hcp/support-center-and-savings'>Support Center</Link></li>
              <li><Link to='/hcp/shares-copay-program'>SHARES Copay Program</Link></li>
              <li><Link to='/hcp/billing-coding'>Billing &amp; Coding</Link></li>
          </ul>
          <h2>Resources</h2>
           <ul className='brand-list'>
              <li><Link to='/hcp/caregiver-resources'>Videos</Link></li>
              <li><Link to='/hcp/downloads-office-support'>Downloads &amp; Links</Link></li>
              <li><Link to='/hcp/request-a-sales-rep'>Request a Rep</Link></li>
          </ul>
        </Col>
    </Row>
  </Layout>
)

export default IndexPage
